<template>
  <div class="loginwrap">
    <div class="rightform">
      <div class="formbox">
        <el-row>
          <el-col class="api-center" :xs="18" :sm="15" :md="13" :lg="11">
            <h2 class="api-title">忘记密码</h2>
            <p class="api-text">
              输入您的注册邮箱<br/>
              我们将会发送一封邮件以帮助您重新设置密码
            </p>
            <el-form ref="loginFormRef" :rules="loginFormRules" class="loginform" :model="loginForm">
              <el-form-item prop="email">
                <el-input
                  placeholder="请输入邮箱"
                  v-model="loginForm.email"
                  @keyup.enter.native="handellogoForm('loginFormRef')"
                >
                  <i slot="prefix" class="iconfont iconyouxiang3"></i>
                </el-input>
              </el-form-item>
              <el-form-item class="api-forget">
                <span class="fr">
                  已有账号，请
                  <router-link :to="{ name:'login' }" tag="a">登录</router-link>
                </span>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  style="width:100%;background:#2F95E3;border:none;"
                  v-loading="btnLoading"
                  @click="handellogoForm('loginFormRef')"
                >发送重置密码链接</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { forgetRequest } from "@/api/user.js";

export default {
  data() {
    return {
      btnLoading: false,
      loginForm: {
        email: ''
      },
      loginFormRules: {
        email:[
           { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: "blur"}
        ]}
    };
  },
  methods: {
    async registerFormSubmit() {
      const result = await forgetRequest({
        email:this.loginForm.email
      });
      if (result.status === 200) {
         this.$message({
          showClose: true,
          message: result.message,
          type: "success"
        });
        this.$router.push({ name: "reset" });
      } else {
        this.$message({
          showClose: true,
          message: result.message,
          type: "error"
        });
        this.btnLoading = false;
      }
      this.btnLoading = false;
    },
    handellogoForm(formName) {
      this.$refs[formName].validate(valid => {
        this.btnLoading = true;
        try {
          if (!valid) {
            this.btnLoading = false;
            return;
          }
          this.registerFormSubmit();
        } catch (err) {
          this.$message({
            showClose: true,
            message: "发送失败",
            type: "error"
          });
          this.btnLoading = false;
          console.log(err);
        }
      });
    },
  }
};
</script>
<style lang="less" scoped>
/deep/html,
/deep/body,
/deep/#app {
  margin: 0;
  padding: 0;
  height: auto;
  min-height: 100%;
}
.loginwrap {
  height: 100%;
  min-height: 100%;
  background: url("../../../assets/imgs/loginbg.png");
  background-size: 100% 100%;
  display: flex;
  .leftimgbox {
    flex: 1;
    box-sizing: border-box;
    padding: 50px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  .rightform {
    flex: 1;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
    .formbox {
      width: 100%;
      padding: 20px;
      & > .el-row {
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
    }
    /deep/.api-center {
      background: #fff;
      padding: 50px 7%;
      -webkit-box-shadow: 0px 0px 10px #c7ddec;
      box-shadow: 0px 0px 17px #c7ddec;
      border-radius: 10px;
      .api-title {
        font-size: 22px;
        line-height: 50px;
        text-align: center;
        margin-bottom: 10px;
      }
      .api-text {
        font-size: 17px;
        margin-bottom: 40px;
        text-align: center;
        line-height: 36px;
      }
      /deep/.el-input__prefix {
        left: 12px;
      }
      /deep/.el-input--prefix .el-input__inner {
        padding-left: 35px;
      }
      .api-forget {
        margin: -10px 0 15px 0;
        font-size: 13px;
        a {
          color: #2f95e3;
          text-decoration: none;
        }
      }
    }
  }
}
</style>